import React, { useEffect, useState } from 'react';
import {
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  AutocompleteInput,
  DateInput,
  BooleanInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelButton from './CancelButton';
import { getProductDetails } from '../../DataProvider/Corporate';
import _ from 'lodash';
import moment from 'moment';

const styles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const FormToolbar = props => {
  let errorText = '';
  const selectedDate = moment(props.selectedDateValue);
  const today = moment();
  if(!props.selectedSubscriptionType) {
    errorText = 'SUBSCRIPTION TYPE cannot be empty';
  } else if(!props.selectedSubscriptionPlan) {
    errorText = 'SUBSCRIPTION PLAN cannot be empty';
  } else if(!props.selectedDateValue) {
    errorText = 'EXPIRY DATE cannot be empty';
  } else if(['trial', 'month', 'lifetime', 'year', 'cancelled'].includes(props.selectedSubscriptionType) && 
  selectedDate.isBefore(today)) {
    errorText = `${props.selectedSubscriptionType.toUpperCase()} subscription type must have an expiry greater than today's date`
  } else if(props.selectedSubscriptionType === 'expired' && selectedDate.isAfter(today)) {
    errorText = `EXPIRED subscription type must have an expiry less than today's date`
  } else {
    errorText = '';
  }
  return (
  <>
    <span style={{marginLeft:'16px', fontSize:'14px', color: 'red'}}>{errorText}</span>
    <Toolbar {...props} >
      <SaveButton label="Update" disabled={errorText} />
    </Toolbar>
  </>
)};

const UserSubscriptionTab = withStyles(styles)(({ classes, ...props }) => {
  const [subscriptionTypeArray, setSubscriptionTypeArray] = useState([]);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState("");
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState("");
  const [dateRange, setDateRange] = useState({});
  const [selectedDateValue, setSelectedDateValue] = useState(null);
  const [prevSubType, setPrevSubType] = useState("")
  const today = moment().format('YYYY-MM-DD');


  const handleValidSubscriptions = (newSubscription) => {
    setSelectedSubscriptionPlan(newSubscription);
    if(newSubscription === 'personal') {
      setSubscriptionTypeArray([  
      { id: 'trial', name: 'Trial' },
      { id: 'month', name: 'Month' },
      { id: 'lifetime', name: 'Lifetime' },
      { id: 'year', name: 'Year' },
      { id: 'expired', name: 'Expired' },
      { id: 'cancelled', name: 'Cancelled' }])
      if(!selectedSubscriptionType) {
        handleSubscriptionType(prevSubType);
      }
    } else if(newSubscription === 'coupon') {
      setSubscriptionTypeArray([{ id: 'month', name: 'Month' },
      { id: 'expired', name: 'Expired' },])
      if(!['month', 'expired'].includes(selectedSubscriptionType)) {
        handleSubscriptionType("");
        selectedSubscriptionType && setPrevSubType(selectedSubscriptionType);
      }
    } else if(newSubscription === 'gift') {
      setSubscriptionTypeArray([  
      { id: 'month', name: 'Month' },
      { id: 'lifetime', name: 'Lifetime' },
      { id: 'year', name: 'Year' },
      { id: 'expired', name: 'Expired' },])
      if(['trial', 'cancelled'].includes(selectedSubscriptionType)) {
        handleSubscriptionType("");
        selectedSubscriptionType && setPrevSubType(selectedSubscriptionType);
      } else {
        handleSubscriptionType(prevSubType);
      }
    } else if(newSubscription === 'promotion') {
      setSubscriptionTypeArray([
      { id: 'month', name: 'Month' },
      { id: 'expired', name: 'Expired' },]);
      if(!['month', 'expired'].includes(selectedSubscriptionType)) {
        handleSubscriptionType("");
        selectedSubscriptionType && setPrevSubType(selectedSubscriptionType);
      }
    }
  }

  const handleSubscriptionType = (newSubscriptionType) => {
    setSelectedSubscriptionType(newSubscriptionType);
    if(newSubscriptionType === 'expired') {
      setDateRange({max: today})
    } else {
      setDateRange({min: today})
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const productDetails = JSON.parse(localStorage.getItem('product-details')) || {};
      if (_.isEmpty(productDetails)) {
        const token = await localStorage.getItem('token');
        await getProductDetails(token);
      }
    };

    const personalDetails = JSON.parse(localStorage.getItem('personalDetails'));

    personalDetails.subscription_plan && handleValidSubscriptions(personalDetails.subscription_plan);
    personalDetails.subscription_type && handleSubscriptionType(personalDetails.subscription_type);
    personalDetails.subscription_expiration_date_formatted && setSelectedDateValue(personalDetails.subscription_expiration_date_formatted);
    fetchData();
  }, []);


return (
  <Edit title='Update' actions={<CancelButton />} {...props} >
    <SimpleForm toolbar={<FormToolbar selectedSubscriptionPlan={selectedSubscriptionPlan} selectedSubscriptionType={selectedSubscriptionType} selectedDateValue={selectedDateValue} />}>
    <AutocompleteInput label='Subscription Plan' source='subscription_plan' formClassName={classes.inlineBlock} choices={[
        { id: 'personal', name: 'Personal' },
        { id: 'gift', name: 'Gift' },
        { id: 'promotion', name: 'Promotion' },
        { id: 'coupon', name: 'Coupon' },
      ]}
      onChange={newSubscription => handleValidSubscriptions(newSubscription)}
       />
      <AutocompleteInput disabled={!selectedSubscriptionPlan} onChange={newSubscriptionType => handleSubscriptionType(newSubscriptionType)} label='Subscription Type' source='subscription_type' formClassName={classes.inlineBlock} choices={subscriptionTypeArray} />
      <DateInput InputProps={{
      inputProps: dateRange,
    }} label='Subscription Expiry' disabled={!selectedSubscriptionPlan || !selectedSubscriptionType} source='subscription_expiration_date_formatted' onChange={(event) => setSelectedDateValue(event.target.value)} formClassName={classes.inlineBlock} />
        <BooleanInput label='Mailchimp Status' source='mailChimpStatus' formClassName={classes.inlineBlock} />
    </SimpleForm>
  </Edit>
);
});

export default UserSubscriptionTab;
