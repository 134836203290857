import axios from 'axios';
import _ from 'lodash';

export const getCorporateUserInformation = async (allId) => {
  const ids = allId.split('/');
  const url = `${process.env.REACT_APP_API_BASE_URL}/corporate-user?version_id=${ids[1]}&corporate_id=${ids[0]}&action=fetchUser`;
  const token = localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin'
    }
  }).then(response => {
    if (_.has(response, ['data', 'data'])) {
      return response.data;
    } else {
      return {};
    }
  }).catch((err) => {
    console.log(err.message);
  });
};

export const updateCorporateUserStatus = (email, status, action) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/corporate-user`;
  const token = localStorage.getItem('token');
  const data = { email, status, action };
  return axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin'
    },
  }).then(response => {
    return response.data;
  }).catch((err) => {
    console.log(err);
  });
};

export const updateNewsletterStatus = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/subscription`;
  const token = localStorage.getItem('token');
  return axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin'
    },
  }).then(response => {
    return response.data;
  }).catch((err) => {
    console.log(err);
  });
};

export const getProductDetails = (token) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/product-details`;

  try {
    if (!token) {
      console.error('Token is missing!');
      throw new Error('Authorization token is required.');
    }

    return axios({
      url: url,
      method: 'get',
      headers: { 'authorization': `Bearer ${localStorage.getItem('token')}`,
      cert: 'admin',
      'Content-Type': 'application/json',
      platform: 'ops'
     },
    })
      .then(response => {
        const keyByProducts = response.data.data.reduce((acc, item) => {
          acc[item.sub_plan.toLowerCase()] = item.id;
          return acc;
        }, {});
        keyByProducts && localStorage.setItem('product-details', JSON.stringify(keyByProducts));
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching product details:', error || error.message);
      });
  } catch (err) {
    console.error('Unexpected error:', err);
  }
};

export const getCorporateEdition = async () => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/corporate-edition`;
  const token = localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin'
    }
  }).then(response => {
    return response.data;
  }).catch((err) => {
    console.log(err.message);
  });
};

export const updateUserRole = async (data) => {
  console.log("updateUserRole...")
  const url = `${process.env.REACT_APP_API_BASE_URL}/admin-login`;
  const token = localStorage.getItem('token');
  return axios.patch(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      action: 'corporate',
      platform: 'ops',
    },
  }).then(response => {
    return response.data;
  }).catch((err) => {
    console.log(err);
  });
};

export const addCorporateUsers = async (data) => {
  console.log("addCorporateUsers")
  const url = `${process.env.REACT_APP_API_BASE_URL}/corporate-user`;
  const token = localStorage.getItem('token');
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin',
    }
  }).then(response => {
    return {existingUserCount: response.data.existing_user.count,
            newUserCount: response.data.news_user.count,
            userErrorCount: response.data.tech_error.count};
  }).catch((error) => {
    console.log("Request Error:", error); // Log request error for debugging
    return []; // Return an empty array or handle the error as needed
  });
}

export const getCorporateInfo = async (data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/corporate/${data}`;
  const token = localStorage.getItem('token');
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin',
    }
  }).then(response => {
    return response.data;
  }).catch((error) => {
    console.log("Request Error:", error);
    return [];
  });
}


export const patchCorporateInfo = async (id, data) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/corporate/${id}`;
  const token = localStorage.getItem('token');

  return axios.patch(url,data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      cert: 'admin',
    }
  }).then(response => {
    return response.data;
  }).catch((error) => {
    console.log("Request Error:", error);
    return [];
  });
}


export const sortingAdminUsers = (data) => {

  let adminIndex = 0;

for (let i = 0; i < data.length; i++) {
  if (data[i].role === 'Administrator') {
    [data[i], data[adminIndex]] = [data[adminIndex], data[i]];
    adminIndex++;
  }
}

return data;
 
}