import axios from 'axios';
import _ from 'lodash';

export const saveFeed = (feedName, feedUrl) => {
    const data = {
        url: feedUrl.trim(),
        name: feedName,
        status: 'active'
    };
    const addFeedUrl = `${process.env.REACT_APP_EDITOR_API_BASE_URL}/external-feed`;
    return axios({
      url: addFeedUrl,
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
        cert: 'admin'
      },
      method: 'post',
      data
    })
      .then(response => {
        if (_.has(response, 'data')) {
          return { error: false };
        }
      })
      .catch(error => {
        console.log('error', error.response);
        return {error: true, msg: _.get(error, 'response.data.message', '')};
      });
  }

export const deleteFeed = (feedId) => {
if(feedId) { 
  const url = `${process.env.REACT_APP_EDITOR_API_BASE_URL}/external-feed/${feedId}`;
  const token = localStorage.getItem('token');

  return axios
      .delete(url, {
      headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          cert: 'admin',
          platform: 'ops'
      }
  })
      .then(response => {
        if (_.has(response, 'data')) {
          return { error: false };
        }
      })
      .catch(err => {
        console.log(err.message);
        return {error: true};
      });
  } else {
    return {error: true};
  }
};

export const getFeedsList = async () => {
const url = `${process.env.REACT_APP_EDITOR_API_BASE_URL}/external-feed?status=active`;
const token = localStorage.getItem('token');
return axios.get(url, {
    headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    }
}).then(response => {
    if(_.has(response,['data','data']) && response.data.data.length){
        return response.data.data;
    }
    return [];
}).catch((err) => {
    console.log(err.message);
});
};