import React, { useState, useEffect } from "react";
import { Button, useNotify  } from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import { useStyles } from "../styles/ExternalFeeds";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
  Button as MuiButton,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { saveFeed, deleteFeed, getFeedsList } from "../../DataProvider/ExternalFeeds";

const ExternalFeeds = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const [isAddFeedModalOpen, setIsAddFeedModalOpen] = useState(false);
  const [feedName, setFeedName] = useState("");
  const [feedUrl, setFeedUrl] = useState("");
  const [feedList, setFeedList] = useState([]);


  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState(null); // To track the feed to delete

  // Handlers for Add Feed modal
  const openAddFeedModal = () => setIsAddFeedModalOpen(true);
  const closeAddFeedModal = () => {
    setIsAddFeedModalOpen(false);
    setFeedUrl("");
    setFeedName("");
  }

  // Handlers for Delete modal
  const openDeleteModal = (feed) => {
    setSelectedFeed(feed); // Set the feed to delete
    setIsDeleteModalOpen(true);
  };
  const closeDeleteModal = () => {
    setSelectedFeed(null);
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    // Define the async function inside useEffect
    const fetchFeeds = async () => {
      const feedListResponse = await getFeedsList();
      setFeedList(feedListResponse);
    };
  
    // Call the async function
    fetchFeeds();
  }, []);

  const handleSaveFeed = async () => {
    const response = await saveFeed(feedName, feedUrl)
    if(response.error) {
      notify(`Error Occured while adding Feed, ${response.msg}`, { type: 'danger' })
    } else {
      notify(`Feed has been added`, { type: 'success' })
    }
    closeAddFeedModal();
    const feedListResponse = await getFeedsList();
    setFeedList(feedListResponse);
  }

  const handleFeedDeletion = async () => {
    const feedId = selectedFeed._id
    const deleteFeedResponse = await deleteFeed(feedId)
    if(deleteFeedResponse.error) {
      notify(`Some error Occured while deleting Feed`, { type: 'danger' })
    } else {
      notify(`Feed has been deleted`, { type: 'success' })
    }
    closeDeleteModal();
    const feedListResponse = await getFeedsList();
    setFeedList(feedListResponse);
    
  }

  return (
    <>
      <div className={classes.feedButtonContainer}>
        <Button
          label="ADD FEED"
          className={classes.addFeedButton}
          onClick={openAddFeedModal}
        />
      </div>

      <table className={classes.table}>
      <thead>
        <tr className={classes.tableHeaderRow}>
          <th className={classes.tableHeaderCell}>Feed</th>
          <th className={classes.tableHeaderCell}>Link</th>
          <th className={classes.tableHeaderCell}>Added</th>
          <th className={classes.tableHeaderCell}>Delete</th>
        </tr>
      </thead>
      <tbody>
        {feedList.map((feed) => (
          <tr key={feed._id} className={classes.tableRow}>
            <td className={classes.tableCell}>{feed.name}</td>
            <td className={classes.tableCell}>{feed.url}</td>
            <td className={classes.tableCell}>
              {new Date(feed.createdAt).toLocaleDateString()}
            </td>
            <td className={classes.tableCell}>
              <DeleteIcon className={classes.deleteIcon} onClick={() => openDeleteModal(feed)}/>
            </td>
          </tr>
        ))}
      </tbody>
    </table>

      {/* Modal */}
      <Dialog
        open={isAddFeedModalOpen}
        onClose={closeAddFeedModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Add New Source
          <IconButton
            aria-label="close"
            onClick={closeAddFeedModal}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <hr className={classes.horizontalRule} />
        
        <DialogContent>
        <MuiTextField
          label="Feed Name"
          placeholder="Feed Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={feedName}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#010203", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#010203", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#010203", // Border color on focus (click)
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#010203", // Label color when focused
            },
          }}
          onChange={(e) => setFeedName(e.target.value)}
          />
        <MuiTextField
          label="Add URL"
          placeholder="Add URL"
          variant="outlined"
          fullWidth
          margin="normal"
          value={feedUrl}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#010203", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#010203", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#010203", // Border color on focus (click)
              },
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#010203", // Label color when focused
            },
          }}
          onChange={(e) => setFeedUrl(e.target.value)}
        />
        </DialogContent>
        <hr className={classes.horizontalRule} style={{marginBottom: '20px'}} />
        <DialogActions>
          <MuiButton onClick={closeAddFeedModal}  sx={{ color: "#010203", borderColor: "#010203" }} variant="outlined">
            Close
          </MuiButton>
          <MuiButton
            onClick={handleSaveFeed}
            variant="contained"
            sx={{ background: "#010203" }}
          >
            Add New Source
          </MuiButton>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Confirm Deletion
          <IconButton
            aria-label="close"
            onClick={closeDeleteModal}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
          <hr className={classes.horizontalRule} />
        <DialogContent>
          <p>
            Are you sure you want to delete the feed{" "}
            <strong>{selectedFeed && selectedFeed.name}</strong>?
          </p>
       </DialogContent>
        <hr className={classes.horizontalRule} style={{marginBottom: '12px'}} />
        <DialogActions>
          <MuiButton onClick={closeDeleteModal} sx={{ color: "#010203", borderColor: "#010203" }} variant="outlined">
            Cancel
          </MuiButton>
          <MuiButton
            onClick={handleFeedDeletion}
            variant="contained"
            sx={{ background: "#010203" }}
          >
            Delete
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExternalFeeds;
