import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  addFeedButton: {
    borderRadius: '4px',
    border: '1px solid #010203',
    textAlign: 'center',
    fontFamily: "Suisse Int'l",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    height: '36px',
    width: '106px',
    color: '#010203',
    float: 'right'
  },
  feedButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '24px'
  },
  horizontalRule: {
    border: "none",
    borderTop: "1px solid #CED4DA",
    margin: 0, 
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    fontFamily: 'Arial, sans-serif',
    marginTop: '30px'
  },
  tableHeaderRow: {
    backgroundColor: '#f9f9f9',
    borderBottom: '2px solid #e0e0e0',
  },
  tableHeaderCell: {
    textAlign: 'left',
    fontWeight: 'bold',
    color: '#555',
    padding: '12px',
    fontSize: '14px',
  },
  tableRow: {
    '&:nth-child(even)': {
      backgroundColor: '#f5f5f5',
    },
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  tableCell: {
    padding: '10px',
    borderBottom: '1px solid #e0e0e0',
    color: '#777',
    fontSize: '14px',
  },
  deleteIcon: {
    color: '#777',
    cursor: 'pointer',
    transition: 'color 0.2s ease-in-out',
    '&:hover': {
      color: '#e74c3c', // Red hover effect for delete icon
    },
  },
});
