import React, { useState } from 'react';
import { List, Datagrid, TextField, EditButton, FunctionField, useNotify } from 'react-admin';
import { Pagination } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button as MuiButton,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { businessShutdown } from '../../DataProvider/Business'
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
  horizontalRule: {
    border: "none",
    borderTop: "1px solid #CED4DA",
    margin: 0, 
  }
})

const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 25, 10]} {...props} />
);



const Business = (props, i) => { 
  const [modalVisibility, setModalVisibility] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const classes = useStyles();
  const notify = useNotify();

  const handleBusinessShutdown = async () => {
    const email = localStorage.getItem('username');
    const data = {
      org_id: selectedBusiness.id,
      action: 'abandonBusiness',
      reasonText: `Business abandoned from ops by user: ${email}`
    };
    const shutdownBusinessResponse = await businessShutdown(data);
    console.log("shutdownBusinessResponse",shutdownBusinessResponse);
    setModalVisibility(false);
    if(shutdownBusinessResponse.error) {
      notify(`Some error Occured while closing Business ${selectedBusiness.name}`, { type: 'danger' });
    } else {
      notify(`Business ${selectedBusiness.name} has been closed`, { type: 'success' })
      setTimeout(() => window.location.reload(),1100);
    }
  }

  const handleDeletionModal = (record) => {
    console.log("handleDeletionModal",record);
    setSelectedBusiness({id: record.id, name: record.name});
    setModalVisibility(true);
  }
  
  return (
  <>
  <List title='Business'
   {...props}
   bulkActionButtons={false}
   perPage={50}
   pagination={<CustomPagination />}
   >
    <Datagrid key={i} rowClick={null}>
    <TextField source='id' label='Business Id' sortable={true} />
    <TextField source='name' label='Name' sortable={true} />
      <TextField source='plan' label='Plan' sortable={false} />
      <TextField source='available_amount' label='Residual news credit' sortable={false} />
      <TextField source='status' label='Status' sortable={true} />
      <TextField source='adminEmail' label='Admin Email' sortable={false}
        style={{
          overflow: 'auto',
          width: 245,   
          display: 'block',
        }}  
      />
      <TextField source='activeUsers' label='Active Users' sortable={false} />
      <FunctionField
        render={(record) => (
          <MuiButton
            onClick={() => handleDeletionModal(record)}
            color="primary"
            variant="contained"
            disabled={record.status === "Abandoned"}
          >
            Close
          </MuiButton>
        )}
      />

      <EditButton />
    </Datagrid>
  </List>
  <Dialog
        open={modalVisibility}
        onClose={() => setModalVisibility(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Close Business
          <IconButton
            aria-label="close"
            onClick={() => setModalVisibility(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <hr className={classes.horizontalRule} />
        <p style={{margin: '20px'}}>
        Are you sure you would like to mark this business as abandoned<br />
            <strong>{selectedBusiness && `${selectedBusiness.name}(${selectedBusiness.id})` }</strong>?
          </p>

          <hr className={classes.horizontalRule} style={{marginBottom: '20px'}} />
        <DialogActions>
          <MuiButton onClick={() => setModalVisibility(false)}  sx={{ color: "#010203", borderColor: "#010203" }} variant="outlined">
            Cancel
          </MuiButton>
          <MuiButton
            onClick={() => handleBusinessShutdown()}
            variant="contained"
            sx={{ background: "#010203" }}
          >
            Close Business
          </MuiButton>
        </DialogActions>
      </Dialog>
      </>
)};

export default Business;
